<template>
  <div class="w-100">
    <b-row>
      <b-col cols="3" v-for="val in data" :key="val.id">
        <b-card class="cursor-pointer" @click="$router.push({name: 'AcceptedApplicationStages', params:{stageId: val.id}})">
          <div class="text-center">
            {{val.stage_name}}
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getStages } from '@/api/nomination'

export default {
  name: 'AcceptedStages',
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.fetchStages()
  },
  methods: {
    fetchStages() {
      getStages().then(res => {
        this.data = res.data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>
